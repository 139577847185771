<template>
  <v-container class="pa-0" fluid>
    <v-row v-if="!loading" >
      <v-col cols="12" md="5">
        <v-toolbar class="mb-2" v-if="editItem[fields.id]">
          <v-btn icon @click.stop="editItem = {}">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
          Editando
        </v-toolbar>
        <AutoCrudEdit
          :_item="editItem"
          :fields="fields"
          :router_prefix="router_prefix"
          :key="editItem[fields.id]"
          @reload="getItems()"
        />
      </v-col>
      <v-col cols="12" md="7">
        <template v-for="([title, items], k) in Object.entries(grouppedItems)">
          <v-subheader :key="k + 1">{{ title }}</v-subheader>
          <v-card
            :key="-(k + 1)"
            class="d-flex flex-wrap pa-2 align-start"
            style="gap: 15px"
          >
            <v-alert
              max-width="500"
              style="width: 100%"
              class="ma-0"
              text
              prominent
              dense
              :key="i"
              v-for="(item, i) in items"
              :color="
                item.color ||
                  fields.baseColor ||
                  getSituacionIcon(item[fields.situacion]).color
              "
              :icon="
                item.icon ||
                  fields.baseIcon ||
                  getSituacionIcon(item[fields.situacion]).icon
              "
            >
              <div class="d-flex">
                <div v-text="item[fields.title]" class="text-h6" />
                <v-spacer></v-spacer>
                <v-btn
                  text
                  small
                  rounded
                  :color="
                    item.color ||
                      fields.baseColor ||
                      getSituacionIcon(item[fields.situacion]).color
                  "
                  @click.stop="editItem = item"
                >
                  <v-icon left>mdi-pencil</v-icon>
                  <!-- <v-icon v-else left>mdi-lock</v-icon> -->
                  Editar
                </v-btn>
              </div>
              <v-sheet
                width="100%"
                height="2px"
                class="mb-2"
                :color="
                  item.color ||
                    fields.baseColor ||
                    getSituacionIcon(item[fields.situacion]).color
                "
              ></v-sheet>
              <div>{{ item[fields.descripcion] }}</div>
            </v-alert>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getSituacionIcon } from "@/utils/index.js";

export default {
  components: {
    AutoCrudEdit: () => import("../components/AutoCrudEdit.vue"),
    // StatusChip: () => import("@/components/StatusChip.vue"),
    // EditarTipoContacto: () => import("../components/EditarTipoContacto.vue"),
    // Editable: () => import("@/components/Editable.vue"),
  },
  props: {
    model: String,
    router_prefix: String,
  },
  data() {
    return {
      // nuevoEstado: {
      //   icon: null,
      //   color: null,
      //   text: null,
      // },
      // estadoEdit: {},
      editItem: {},
      items: [],
      fields: {},
      loading: true,
    };
  },
  methods: {
    getSituacionIcon,
    async describeModel() {
      this.loading = true;
      const { data } = await axios(
        `${process.env.VUE_APP_API_URL}/autocrud/${this.model}`
      );
      this.fields = data;
      this.loading = false;
    },
    async getItems() {
      const { data } = await axios(
        `${process.env.VUE_APP_API_URL}/${this.router_prefix}`
      );
      this.items = data;

      this.editItem = Object.create(Object.prototype);
    },
  },
  computed: {
    grouppedItems() {
      if (!this.fields.groupBy) return { [this.model]: this.items };
      return this.items.reduce((r, c) => {
        const title = `${this.fields.groupBy}: ${c[this.fields.groupBy]}`;
        r[title] ||= [];
        r[title].push(c);
        return r;
      }, {});
    },
  },
  mounted() {
    this.describeModel();
    this.getItems();
  },
};
</script>

<style></style>
