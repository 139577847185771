var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(!_vm.loading)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[(_vm.editItem[_vm.fields.id])?_c('v-toolbar',{staticClass:"mb-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.editItem = {}}}},[_c('v-icon',[_vm._v("mdi-arrow-left-thick")])],1),_vm._v(" Editando ")],1):_vm._e(),_c('AutoCrudEdit',{key:_vm.editItem[_vm.fields.id],attrs:{"_item":_vm.editItem,"fields":_vm.fields,"router_prefix":_vm.router_prefix},on:{"reload":function($event){return _vm.getItems()}}})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_vm._l((Object.entries(_vm.grouppedItems)),function([title, items],k){return [_c('v-subheader',{key:k + 1},[_vm._v(_vm._s(title))]),_c('v-card',{key:-(k + 1),staticClass:"d-flex flex-wrap pa-2 align-start",staticStyle:{"gap":"15px"}},_vm._l((items),function(item,i){return _c('v-alert',{key:i,staticClass:"ma-0",staticStyle:{"width":"100%"},attrs:{"max-width":"500","text":"","prominent":"","dense":"","color":item.color ||
                _vm.fields.baseColor ||
                _vm.getSituacionIcon(item[_vm.fields.situacion]).color,"icon":item.icon ||
                _vm.fields.baseIcon ||
                _vm.getSituacionIcon(item[_vm.fields.situacion]).icon}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-h6",domProps:{"textContent":_vm._s(item[_vm.fields.title])}}),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":"","rounded":"","color":item.color ||
                    _vm.fields.baseColor ||
                    _vm.getSituacionIcon(item[_vm.fields.situacion]).color},on:{"click":function($event){$event.stopPropagation();_vm.editItem = item}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Editar ")],1)],1),_c('v-sheet',{staticClass:"mb-2",attrs:{"width":"100%","height":"2px","color":item.color ||
                  _vm.fields.baseColor ||
                  _vm.getSituacionIcon(item[_vm.fields.situacion]).color}}),_c('div',[_vm._v(_vm._s(item[_vm.fields.descripcion]))])],1)}),1)]})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }